
$(document).ready(function () {
  $(document).on("click", "#refreshWorker", function () {
    showLoader();
    var id = $(this).data('id');
    var name = $(this).data('name');
    var dyno_name = $(this).data('dyno-name');
    var restart_access = $(this).data('can-restart-dynos');
    var change_quantity_access = $(this).data('can-change-quantity');
    var data = { name: name, dyno_name: dyno_name };
    $.ajax({
      url: "/workers/restart/" + id,
      type: 'PUT',
      dataType: 'json',
      data: data,
      success: function (output, status, xhr) {
        call_to_dyno_list(data, name, id, null, null, restart_access,
                          change_quantity_access).then(function (response) {
          var flash = xhr.getResponseHeader('x-flash');
          if (flash) swal.fire(flash)
        })
      }
    });
    return false;
  });

  $(document).on("click", "#WorkerSubmit", function (e) {
    var quantity = $("#Dyno-changeQty").val();
    var id = $(this).data('id');
    var current_dyno_count = $(this).data('dyno-count');
    var name = $(this).data('name');
    var dyno_name = $(this).data('dyno-name');
    var restart_access = $(this).data('can-restart-dynos');
    var change_quantity_access = $(this).data('can-change-quantity');
    var data = { name: name, dyno_name: dyno_name, quantity: quantity };
    if(minimum_dyno_quantity(quantity) == true) return false;
    if (current_dyno_count == quantity) {
      swal.fire('Please change the dyno quantity.');
      return false;
    } else if (current_dyno_count > quantity) {
      var url = "/workers/scale_down"
      var action = 'Remove Dyno'
    }else if (current_dyno_count < quantity){
      var url = "/workers/scale_up"
      var action = 'Add Dyno'
    }
    $(".loaderContainer").fadeIn('slow');
    update_worker_quantity(url, data, action, name, id, dyno_name, quantity,
      restart_access, change_quantity_access)
    return false;
  })
});
    
function update_worker_quantity(url, data, action, name, id, dyno_name, quantity,
  restart_access, change_quantity_access) {
  $.ajax({
    url: url,
    type: 'POST',
    dataType: 'json',
    data: data,
    success: function (output, status, xhr) {
      setTimeout(function () {      
        $.when(call_to_dyno_list(data, name, id, dyno_name, quantity,
          restart_access, change_quantity_access)).
          then(function (response) {
          var flash = xhr.getResponseHeader('x-flash');
          if (output['count'] == 1)
          {
            if (action == 'Add Dyno')
            {
              swal.fire("Success! " + 'Worker' + "."+ response['workers'].length + " added.",
              'Total ' + 'Worker' + ' Dynos - ' + response['workers'].length)   
            }
            else
            {
              swal.fire("Done! " + 'Worker' + "."+ (response['workers'].length + 1) + " stopped.",
              'Total ' + 'Worker' + ' Dynos - ' + response['workers'].length)   
              }
          }
          else
          {
            if (action == 'Add Dyno')
            {
              swal.fire('Success! ' + output['count'] + ' ' + 'Worker' + ' dynos added.',
                'Total ' + 'Worker' + ' Dynos - ' + response['workers'].length)
            }
            else
            {
              swal.fire('Done! ' + output['count'] + ' ' + 'Worker' + ' dynos stopped.',
              'Total ' + 'Worker' + ' Dynos - ' + response['workers'].length)
              }
          }
          
          $(".loaderContainer").hide();
        })
      }, 3000);
    }
  });
}

function showLoader () {
  $(document).ajaxStart(function() {
    $(".loaderContainer").fadeIn('slow');
  }).ajaxStop(function() {
      $(".loaderContainer").hide();
  });
};

function display_flash_message(xhr) {
  var flash = xhr.getResponseHeader('x-flash');
  if (flash) swal.fire(flash)
}

function minimum_dyno_quantity(quantity) {
  if (quantity < 1) {
    swal.fire('Cannot scale to fewer than 1 dynos per process type.');
    return true;
  }
}

function call_to_dyno_list(data, name, id, dyno_name, quantity,
                           restart_access, change_quantity_access) {
  var dfd = jQuery.Deferred();
  $.ajax({
    url: "/workers",
    type: 'GET',
    dataType: 'json',
    data: data,
    success: function (response) {
      dfd.resolve(response);
      list_dynos(response, name, id, dyno_name, quantity, restart_access,
                 change_quantity_access)
    }
  });
  return dfd.promise();
}

function list_dynos(response, name, id, dyno_name, quantity, restart_access,
                    change_quantity_access) {
  var data = { name: name, dyno_name: dyno_name, quantity: quantity };
  $('.mainItems.withLabel.workersListing').empty()
  $('.mainItems.withLabel.workersListing').fadeIn();
  $.each(response['workers'], function (index, dyno) {
    var indiaTime = new Date(dyno['created_at']).toLocaleString("en-US", {
      timeZone: "Asia/Kolkata"
    });
    indiaTime = new Date(indiaTime);
    formatted_time = moment(indiaTime).format('MMMM DD YYYY, h:mm:ss A');
    if (restart_access) {
      var restart_button_attributes = "<a href='javascript:;' class='refresh' id='refreshWorker'" + 
      "data-dyno-name='" + dyno['name'] + "' data-name='" + name + "' data-id='" +
        dyno['id'] + "' data-can-change-quantity='" + change_quantity_access +
        "' data-can-restart-dynos='" + restart_access + "'><img src='/refresh-icon.png' style='height:20px;'></a>"
    } else {
      var restart_button_attributes = ''
    }
    var li_attributes = "<li><span>" + dyno['name'] + "<small>" + formatted_time + " - " +
      moment(indiaTime).fromNow() + "</small></span>" + restart_button_attributes + "</li>"
    if (index <= $(response['workers']).length) {
      $(".mainItems.withLabel.workersListing").append(li_attributes);
    }
  });
  
  button_attributes = "<li class=''><div class='groupedInputs'><div class='input-group'>" +
    "<div class='input-group-prepend'><button class='btn btn-outline-secondary changeQty'" +
    " data-action='decrease' type='button' id='button-addon1'>-</button>" +
    "</div><input type='number' onkeydown='javascript: return event.keyCode == 69 ? false : true'" +
    " id='Dyno-changeQty' class='form-control text-center'placeholder='' value='" +
    $(response['workers']).length + "' aria-label='Example text with button addon'" +
    " aria-describedby='button-addon1'><div class='input-group-append'>" +
    "<button class='btn btn-outline-secondary changeQty' data-action='increase' type='button'" +
    " id='button-addon1'>+</button></div></div><button class='btn btn-outline-success'" +
    " type='button' data-can-change-quantity='" + change_quantity_access + "'data-name='" +
    name + "'data-can-restart-dynos='" + restart_access +
    "' ' data-dyno-count='" + $(response['workers']).length +
    "'id='WorkerSubmit'>Submit</button></div></li>"
  if (change_quantity_access)
  {
    $(".mainItems.withLabel.workersListing").append(button_attributes);
  }
}
