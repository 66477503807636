$(document).ready(function () {
  $(document).on("click", ".refreshDelayedJob", function () {
    var li_class = $(this).closest("li").attr('id');
    $.ajax({
      url: '/long_running_reports/refresh',
      type: 'GET',
      dataType: 'json',
      success: function (response, status, xhr) {
        var flash = xhr.getResponseHeader('x-flash');
        if (flash) {
          swal.fire(flash);
        } 
        $("#"+li_class+" span").text('Long running reports: '+ response)
      }
    })
    return false;
  });

  $(document).on("click", ".viewHandlerDetails", function () {
    $(".handlerDetails").text($(this).data('params'));
  });

  $(document).on("click", "#deleteDelayedJob", function (e) {
    if($(this).data('handler')){
      return true;
    }
    if (confirm('Are you sure?')) {
      $(".loaderContainer").fadeIn('slow');
      var id = $(this).data('report-id');
      var name = $(this).attr('name');
      var li_element = $(this).closest("li")
      $.ajax({
        url: "/long_running_reports/delete",
        type: 'DELETE',
        dataType: "json",
        data: {id : id, name: name},
        success: function (output, status, xhr) {
          var flash = xhr.getResponseHeader('x-flash');
          if (flash == 'Success') {
            li_element.remove();
            swal.fire('Deleted Succesfully.');
          } else
          {
            swal.fire(flash);
          }
          $(".loaderContainer").hide();
        }
      }); 
    }
    return false;
    
  })

  function showLoader () {
    $(document).ajaxStart(function() {
      $(".loaderContainer").fadeIn('slow');
    }).ajaxStop(function() {
        $(".loaderContainer").hide();
    });
  };
});
