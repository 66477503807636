$(document).ready(function () {
  $('.add_cname').prop('disabled', true);
  $('#cname, #confirm_cname').bind("cut copy paste", function(e) {
    e.preventDefault();
     alert("Copy-paste disabled. Please type in your CNAME!");
    $('#cname, #confirm_cname').bind("contextmenu", function(e) {
        e.preventDefault();
    });
  });

  $('#cname').on('keyup keydown', function(e) {
    if (!validateDomain($(this).val())) {
      $('.cname_error').text('invalid CNAME');
      $('.add_cname').prop('disabled', true);
    } else {
      $('.cname_error').html('');
      if (validateDomain($("#confirm_cname").val())){
        $('.add_cname').prop('disabled', false);
      }
    }
  })

  $('#confirm_cname').on('keyup', function(e) {
    if (!validateDomain($(this).val())) {
      $('.confirm_cname_error').text('invalid CNAME');
      $('.add_cname').prop('disabled', true);
    } else {
      $('.confirm_cname_error').html('');
      if (validateDomain($("#cname").val())){
        $('.add_cname').prop('disabled', false);
      }
    }
  })

  function validateDomain(the_domain) {
    // strip off "http://" and/or "www."
    the_domain = the_domain.replace("http://", "");
    the_domain = the_domain.replace("www.", "");

    //match domains like 'first.second.com'
    var reg = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,63}[a-zA-Z0-9](?:\.[a-zA-Z0-9-]{2,}[a-zA-Z0-9])(?:\.[a-zA-Z]{2,})+$/;
    return reg.test(the_domain);
  }

  $(document).on('click', '.add_cname', function(e){
    if(!$("#application_name").val())
    {
      swal.fire('Please select an application.');
      return false;
    }
    var cname = $('#cname').val();
    var confirm_cname = $('#confirm_cname').val();
    if (cname == '' ||  confirm_cname == ''){
      e.preventDefault();
      alert("New CNAME and confirm CNAME can't be blank");
      return false
    }
    if (cname != confirm_cname) {
      e.preventDefault();
      alert("The CNAMEs do not match. Please try again.");
      return false
    } else {
      if (confirm('Are you sure you want to add the CNAME ['+cname+']')) {
        // Save it!
        return true
      } else {
        return false
      }
    }
  })

  setTimeout(function(){
    $('.alert-info').hide();
  }, 5000);
});