// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import Swal from 'sweetalert2';
window.swal = Swal
import $ from 'jquery';  
window.jQuery = $; window.$ = $;
import moment from 'moment'
window.moment = moment
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("jquery")
require("channels")
require("main")
require("user")
require("unlock_table")
require("dyno")
require("workers")
require("delayed_jobs")
require("cname")
require("ip_access_rules")
require("scheduler")
import("jquery/dist/jquery")
import("popper.js/dist/popper");
import("hammerjs/hammer");  
import("jquery-hammerjs/jquery.hammer");
import("doc/index");
import("bootstrap/dist/js/bootstrap");
import("jquery-ui/dist/jquery-ui");