$(document).on("click", ".unlockTable", function (e) {
  var id = $(this).attr('table-id');
  var status = $(this).attr('table-status');
  if (status == 'Available') return false;
    $.ajax({
      url: "/technical_assistance/unlock_tables/release_table",
      type: 'PUT',
      dataType: "json",
      data: {table_id : id},
      success: function (output, status, xhr) {
        var flash = xhr.getResponseHeader('x-flash');
        if (flash.includes('successfully released')) {
          swal.fire(flash).then(function () {
            $(".unlockTable." + id + " small").text('Status:  Available');
            $("a.unlockTable."+ id).contents().unwrap().wrap('<span/>');
          });
        } else
        {
          swal.fire(flash);
        }
      }
    });
})

$(document).ready(function () {
  $("#UnlockTableSearch").keypress(function (e) {
    if (e.keyCode == 13) {
      var email = $("#UnlockTableSearch").val();
      if (!validateEmail(email)) {
        swal.fire('Invalid Email Address');
        return false;
      }  
    }
  });
});
$(document).on("change", "#locationSelect", function (e) {
  var id = $(this).val();
  $('.ReleaseTableList').empty()
  $.ajax({
    url: "/technical_assistance/unlock_tables/list_dinein_areas",
    type: 'GET',
    dataType: "json",
    data: {location_id : id},
    success: function (response, status, xhr) {
      var flash = xhr.getResponseHeader('x-flash');
      if (flash) {
        swal.fire(flash).then(function () {
          $('.ReleaseTableList').fadeOut();
        });
      } else {
        var dropDown = $("#dinein_areaSelect");
        dropDown.empty();
        dropDown.append("<option value=0>Select Dine-in Area </option>");
        $.each(response, function (index, value) {
          dropDown.append("<option value='"+value.id+"'>"+value.name+"</option>")
        });
      }
    }
  });
})
$(document).ready(function () {
  // hide spinner
    $(".TableUnlockloaderContainer").hide();
    $(document).ajaxStart(function() {
      $(".TableUnlockloaderContainer").fadeIn('slow');
    }).ajaxStop(function () {
      setTimeout(function () {
        $(".TableUnlockloaderContainer").hide();      
      },1000)
    });
});
  
$(document).on("change", "#dinein_areaSelect", function (e) {
  var id = $(this).val();
  $('.ReleaseTableList').empty()
  if (id == 0) { return false };
  $.ajax({
    url: "/technical_assistance/unlock_tables/tables",
    type: 'GET',
    dataType: "json",
    data: {dinein_area_id : id},
    success: function (response, status, xhr) {
      var flash = xhr.getResponseHeader('x-flash');
      if (flash) {
        swal.fire(flash).then(function () {
          $('.ReleaseTableList').fadeOut();
        });
      } else {
        $('.ReleaseTableList').fadeIn();
        $.each(response, function (index, value) {
          if (value.status == 'Available')
          {
            var li_attributes = "<li> <div> <span table-id='" + value.id + "'table-status='" + value.status +
              "' href='javascript:;' class='unlockTable " + value.id + "'> <span class=''>" + value.no +
              "</span> <small>Status:  " + value.status + "</small> </span> </div> </li>"
          }
          else
          {
            var li_attributes = "<li> <div> <a table-id='" + value.id + "'table-status='" + value.status +
              "'href='javascript:;' class='unlockTable " + value.id + "'> <span class=''>" + value.no +
              "</span> <small>Status:  " + value.status + "</small> </a> </div> </li>"
          }
          if (index <= $(response).length) {
            $(".ReleaseTableList").append(li_attributes);
          }
        });
      }
    }
  });
})
function validateEmail($email) {
  var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
  return emailReg.test( $email );
}
