$(document).ready(function () {
  $('.add_ip_access_rules').prop('disabled', true);

  $('#ip_address').on("paste", function(e) {
    var ip = e.originalEvent.clipboardData.getData('Text');
    if (!validateIp(ip)) {
      $('.ip_error').text('invalid ip address');
      $('.add_ip_access_rules').prop('disabled', true);
    } else {
      $('.ip_error').html('');
      $('.add_ip_access_rules').prop('disabled', false);
    }
  })

  $('#confirm_ip_address').on("paste", function(e) {
    var ip = e.originalEvent.clipboardData.getData('Text');
    if (!validateIp(ip)) {
      $('.confirm_ip_error').text('invalid ip address');
      $('.add_ip_access_rules').prop('disabled', true);
    } else {
      $('.confirm_ip_error').html('');
      $('.add_ip_access_rules').prop('disabled', false);
    }
  })

  $('#ip_address').on('keypress', function(e) {
    $('.ip_error').text('only copy/paste allowed');
    e.preventDefault();
  })

  $('#confirm_ip_address').on('keypress', function(e) {
    $('.confirm_ip_error').text('only copy/paste allowed');
    e.preventDefault();
  })

  function validateIp(ip_address) {
    reg = /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))|(^\s*((?=.{1,255}$)(?=.*[A-Za-z].*)[0-9A-Za-z](?:(?:[0-9A-Za-z]|\b-){0,61}[0-9A-Za-z])?(?:\.[0-9A-Za-z](?:(?:[0-9A-Za-z]|\b-){0,61}[0-9A-Za-z])?)*)\s*$)/;

    return reg.test(ip_address);
  }

  $(document).on('click', '.add_ip_access_rules', function(e){
    var ip_address = $('#ip_address').val();
    var confirm_ip_address = $('#confirm_ip_address').val();
    var notes = $('#notes').val();
    var location = $('#location').val();
    var company = $('#company').val();

    var mode = $('#mode').val();
    if ($('#application_name').val()=='') {
      swal.fire('Please select an Application.');
      return false;
    }
    if (mode=='') {
      swal.fire('Please select a Mode.');
      return false;
    }
    if (ip_address == ''){
      e.preventDefault();
      swal.fire("Ip Address can't be blank.");
      return false
    }
    if (confirm_ip_address == ''){
      e.preventDefault();
      swal.fire("Confirm Ip Address can't be blank.");
      return false
    }
    if (company == ''){
      e.preventDefault();
      swal.fire("Company ID can't be blank.");
      return false
    }
    if (location == ''){
      e.preventDefault();
      swal.fire("Location ID can't be blank.");
      return false
    }
    if (notes == ''){
      e.preventDefault();
      swal.fire("Notes can't be blank.");
      return false
    }

    if (ip_address != confirm_ip_address) {
      e.preventDefault();
      alert("The IP addresses do not match. Please try again.");
      return false
    } else {
      if (confirm('Are you sure you want to add the IP '+ip_address+'')) {
        // Save it!
        loader();
        return true
      } else {
        return false
      }
    }
  })

  setTimeout(function(){
    $('.alert-info').hide();
  }, 5000);
});