
$(document).ready(function () {
  $(document).on("click", "#refreshWebDyno", function () {
    showLoader();
    var id = $(this).data('id');
    var name = $(this).data('name');
    var dyno_name = $(this).data('dyno-name');
    var restart_access = $(this).data('can-restart-dynos');
    var change_quantity_access = $(this).data('can-change-quantity');
    var data = { name: name, dyno_name: dyno_name };
    $.ajax({
      url: "/dyno/restart/" + id,
      type: 'PUT',
      dataType: 'json',
      data: data,
      success: function (output, status, xhr) {
        call_to_dyno_list(data, name, id, null, null, restart_access,
                          change_quantity_access).then(function (response) {
          var flash = xhr.getResponseHeader('x-flash');
          var preboot = response['preboot']['enabled'] ? 'ON' : 'OFF'
          if (flash) swal.fire(flash,
          'Note: Preboot is ' + preboot + '.')
        })
      }
    });
    return false;
  });
 
  $(document).on("click", "#preBoot", function () {
    showLoader();
    var name = $(this).data('name');
    var status = $(this).data('status');
    var restart_access = $(this).data('can-restart-dynos');
    var change_quantity_access = $(this).data('can-change-quantity');
    var data = { name: name, status: status }
    $.ajax({
      url: "/dyno/preboot",
      type: 'POST',
      dataType: 'json',
      data: data,
      success: function (output, status, xhr) {
        call_to_dyno_list(data, name, null, null, null, restart_access,
                          change_quantity_access).then(function () {
          display_flash_message(xhr)
        })
      }
    });
    return false;
  });

  $(document).on("click", ".changeQty", function (e) {
    var obj = $(this);
    var action = obj.attr('data-action');
    var input = obj.parent().parent().children("input[type=number]");
    var inputValue = parseInt(input.val());
    switch(action){
      case "increase":
        inputValue += 1;
      break;
      case "decrease":
        inputValue -= 1;
      break;
    }
    if(minimum_dyno_quantity(inputValue) == true) return false; 
    input.val(inputValue);
  })

  $(document).on("click", "#DynoSubmit", function (e) {
    var quantity = $("#Dyno-changeQty").val();
    var id = $(this).data('id');
    var current_dyno_count = $(this).data('dyno-count');
    var name = $(this).data('name');
    var dyno_name = $(this).data('dyno-name');
    var restart_access = $(this).data('can-restart-dynos');
    var change_quantity_access = $(this).data('can-change-quantity');
    var data = { name: name, dyno_name: dyno_name, quantity: quantity };
    if(minimum_dyno_quantity(quantity) == true) return false;
    if (current_dyno_count == quantity) {
      swal.fire('Please change the dyno quantity.');
      return false;
    } else if (current_dyno_count > quantity) {
      var url = "/dyno/scale_down"
      var action = 'Remove Dyno'
    }else if (current_dyno_count < quantity){
      var url = "/dyno/scale_up"
      var action = 'Add Dyno'
    }
    $(".loaderContainer").fadeIn('slow');
    update_web_dyno_quantity(url, data, action, name, id, dyno_name, quantity,
      restart_access, change_quantity_access)
    return false;
  })
});

function update_web_dyno_quantity(url, data, action, name, id, dyno_name, quantity,
  restart_access, change_quantity_access) {
  $.ajax({
    url: url,
    type: 'POST',
    dataType: 'json',
    data: data,
    success: function (output, status, xhr) {
      setTimeout(function () {      
        $.when(call_to_dyno_list(data, name, id, dyno_name, quantity,
          restart_access, change_quantity_access)).
          then(function (response) {
          var flash = xhr.getResponseHeader('x-flash');
          if (output['count'] == 1)
          {
            if (action == 'Add Dyno')
            {
              swal.fire("Success! " + 'Web' + "."+ response['dynos'].length + " added.",
              'Total ' + 'Web' + ' Dynos - ' + response['dynos'].length)   
            }
            else
            {
              swal.fire("Done! " + 'Web' + "."+ (response['dynos'].length + 1) + " stopped.",
              'Total ' + 'Web' + ' Dynos - ' + response['dynos'].length)   
              }
          }
          else
          {
            if (action == 'Add Dyno')
            {
              swal.fire('Success! ' + output['count'] + ' ' + 'Web' + ' dynos added.',
                'Total ' + 'Web' + ' Dynos - ' + response['dynos'].length)
            }
            else
            {
              swal.fire('Done! ' + output['count'] + ' ' + 'Web' + ' dynos stopped.',
              'Total ' + 'Web' + ' Dynos - ' + response['dynos'].length)
              }
          }
         
          $(".loaderContainer").hide();
        })
      }, 3000);
    }
  });
}
    
function showLoader () {
  $(document).ajaxStart(function() {
    $(".loaderContainer").fadeIn('slow');
  }).ajaxStop(function() {
      $(".loaderContainer").hide();
  });
};

function display_flash_message(xhr) {
  var flash = xhr.getResponseHeader('x-flash');
  if (flash) swal.fire(flash)
}

function minimum_dyno_quantity(quantity) {
  if (quantity < 1) {
    swal.fire('Cannot scale to fewer than 1 dynos per process type.');
    return true;
  }
}

function call_to_dyno_list(data, name, id, dyno_name, quantity,
                           restart_access, change_quantity_access) {
  var dfd = jQuery.Deferred();
  $.ajax({
    url: "/dyno/index",
    type: 'GET',
    dataType: 'json',
    data: data,
    success: function (response) {
      dfd.resolve(response);
      list_dynos(response, name, id, dyno_name, quantity, restart_access,
                 change_quantity_access)
    }
  });
  return dfd.promise();
}

function list_dynos(response, name, id, dyno_name, quantity, restart_access,
                    change_quantity_access) {
  var data = { name: name, dyno_name: dyno_name, quantity: quantity };
  $('.mainItems.withLabel.workersListing').empty()
  $('.mainItems.withLabel.workersListing').fadeIn();
  $.each(response['dynos'], function (index, dyno) {
    var indiaTime = new Date(dyno['created_at']).toLocaleString("en-US", {
      timeZone: "Asia/Kolkata"
    });
    indiaTime = new Date(indiaTime);
    formatted_time = moment(indiaTime).format('MMMM DD YYYY, h:mm:ss A');
    if (restart_access) {
      var restart_button_attributes = "<a href='javascript:;' class='refresh', id='refreshWebDyno'" +
        "data-dyno-name='" + dyno['name'] + "' data-name='" + name + "' data-id='" +
        dyno['id'] + "' data-can-change-quantity='" + change_quantity_access +
        "' data-can-restart-dynos='" + restart_access + "'><img src='/refresh-icon.png' style='height:20px;'></a>"
    } else {
      var restart_button_attributes = ''
    }
    var li_attributes = "<li><span>" + dyno['name'] + "<small>" + formatted_time + " - " +
      moment(indiaTime).fromNow() + "</small></span>" + restart_button_attributes + "</li>"
    if (index <= $(response['dynos']).length) {
      $(".mainItems.withLabel.workersListing").append(li_attributes);
    }
  });
  
  if (response['preboot']['enabled']) { var css_class = 'active' }
  preboot_attributes = "<li class='full-button add flex'> <a href='javascript:;' class='text-center " +
    css_class + " prebootBtn' data-name='" + name + "' data-status='" +
    !response['preboot']['enabled'] + "' data-can-restart-dynos='" + restart_access + "'"
    + "data-can-change-quantity='" + change_quantity_access +
    "'id='preBoot'><i class='fas fa-circle'></i> Pre-boot</a> </li>"
  if (change_quantity_access || restart_access)
  {
    $(".mainItems.withLabel.workersListing").append(preboot_attributes);
  }

  button_attributes = "<li class=''><div class='groupedInputs'><div class='input-group'>" +
    "<div class='input-group-prepend'><button class='btn btn-outline-secondary changeQty'" +
    " data-action='decrease' type='button' id='button-addon1'>-</button>" +
    "</div><input type='number' onkeydown='javascript: return event.keyCode == 69 ? false : true'" +
    " id='Dyno-changeQty' class='form-control text-center'placeholder='' value='" +
    $(response['dynos']).length + "' aria-label='Example text with button addon'" +
    " aria-describedby='button-addon1'><div class='input-group-append'>" +
    "<button class='btn btn-outline-secondary changeQty' data-action='increase' type='button'" +
    " id='button-addon1'>+</button></div></div><button class='btn btn-outline-success'" +
    " type='button' data-can-change-quantity='" + change_quantity_access + "'data-name='" +
    name + "'data-can-restart-dynos='" + restart_access +
    "' ' data-dyno-count='" + $(response['dynos']).length +
    "'id='DynoSubmit'>Submit</button></div></li>"
  if (change_quantity_access)
  {
    $(".mainItems.withLabel.workersListing").append(button_attributes);
  }
}
  
    
  
    
  
  