
$(document).on("click", ".updateOwnerEmail", function (e) {
  e.preventDefault();
  var owner_id = $(this).attr('owner-id');
  var owner_email = $(this).attr('owner-email');
  var owner_name = $(this).attr('owner-name');
  var company_id = $(this).attr('company-id');
  var current_owner = $(this).attr('current-owner');
  var uses_pricing_v2 = $(this).attr('uses-pricing-v2');
  if (current_owner == 'true') return false;
  if (confirm("Are you sure you want to set this user as the Current Owner?")) {
    $.ajax({
      url: "/technical_assistance/user/update_owner",
      type: 'PUT',
      dataType: 'json',
      data: { owner_id: owner_id, company_id: company_id, 
              owner_email: owner_email, name: owner_name, 
              uses_pricing_v2: uses_pricing_v2 },
      success: function (output, status, xhr) {
        var flash = xhr.getResponseHeader('x-flash');
        if (flash != 'Success') {
          swal.fire(flash);
        } else {
          $(".updateOwnerEmail").removeClass("current");
          $('.updateOwnerEmail.' + owner_id).addClass("current");
          $(".updateOwnerEmail").attr('current-owner', 'true');
        }
      }
    });
  }
})

$(document).ready(function () {
  $("#CompanyOwnerSearch").keypress(function (e) {
    if (e.keyCode == 13) {
      CompanyOwnerList($(this));
      return false;
    }
  });
  $("#ReleaseEmailSearch").keypress(function (e) {
    if (e.keyCode == 13) {
      ReleaseEmailList($(this));
      return false;
    }
  });
});

$(document).on("click", "#ReleaseEmailSearchImg", function (e) {
  ReleaseEmailList($(this));
  return false;
})

$(document).on("click", "#CompanyOwnerSearchImg", function (e) {
  CompanyOwnerList($(this));
  return false;
})

$(document).on("click", "#releaseEmail", function (e) {
  var email = $(".release span.email").text()
  var id = $(this).attr('user-id');
  var current_owner = $(this).attr('current-owner');
  if (current_owner == 'true') return false;
  if (confirm("Are you sure you want to release this email?")) {
    $.ajax({
      url: "/technical_assistance/user/release_email",
      type: 'PUT',
      dataType: 'json',
      data: { id: id },
      success: function (output, status, xhr) {
        var flash = xhr.getResponseHeader('x-flash');
        if (flash.includes('Successfully Released')) {
          swal.fire("Email: " + email +" Was Successfully Released.").then(function () {
            $('.OwnerDetailsSearchResult').fadeOut();
          });
        }
      }
    });
  }
  return false;
});

var ReleaseEmailList = function (that) {
  var email = $("#ReleaseEmailSearch").val();
  if (!validateEmail(email)) {
    swal.fire('Invalid Email Address');
    return false;
  }
  $.ajax({
    url: "/technical_assistance/user/owner_details",
    type: 'GET',
    dataType: 'json',
    data: { email: email },
    success: function (response, status, xhr) {
      var flash = xhr.getResponseHeader('x-flash');
      if (flash) {
        swal.fire(flash).then(function () {
          $('.OwnerDetailsSearchResult').fadeOut();
        });
      }
      else {
        $('.OwnerDetailsSearchResult').empty()
        $('.OwnerDetailsSearchResult').fadeIn();
        var deleted = (response.deleted) ? "Yes" : "No";
        if (response.current_owner && response.subscription_status != 'Cancelled') {
          var li_attributes = "<li id='ReleaseEmailList'> <div> <span current-owner='true' user-id='"
            + response.user_id + "'   class='release' id='releaseEmail'> <span class='email'>" + email +
            "</span> <small class='company'>" + response.company_name +
            "</small> <small class='others'>Deleted: " + deleted +
            "</small><small class='others'>Status: " + response.subscription_status + "</small> </span> </div> </li>"
        } else {
          var li_attributes = "<li id='ReleaseEmailList'> <div> <a user-id='" + response.user_id +
            "' href=''  class='release' id='releaseEmail'> <span class='email'>" + email +
            "</span> <small class='company'>" + response.company_name +
            "</small> <small class='others'>Deleted: " + deleted +
            "</small><small class='others'>Status: " + response.subscription_status +"</small> </a> </div> </li>"
        }
        if ($('.OwnerDetailsSearchResult li').length < 1) {
          $(".OwnerDetailsSearchResult").append(li_attributes);
        }
      }
    }
  });
}

var CompanyOwnerList = function (that) {
  var email = $("#CompanyOwnerSearch").val();
  if (!validateEmail(email)) {
    swal.fire('Invalid Email Address');
    return false;
  }
  $.ajax({
    url: "/technical_assistance/user/list_company_owners",
    type: 'GET',
    dataType: 'json',
    data: { email: email },
    success: function (response, status, xhr) {
      var flash = xhr.getResponseHeader('x-flash');
      if (flash) {
        swal.fire(flash).then(function () {
          $('.OwnersSearchResult').fadeOut();
        });
      } else {
        $('.OwnersSearchResult').empty()
        $('.OwnersSearchResult').fadeIn();
        if ($('.OwnerDetailsSearchResult li').length < 1) {
          if (response.current_owner_email == response.user.email) {
            var li_attributes = "<li id=" + response.user.id + "> <div> <a current-owner='true' owner-id='" +
              response.user.id + "' company-id='" + response.company_id + "'owner-email='" + response.user.email + 
              "'uses-pricing-v2='" + response.uses_pricing_v2 +
              "'owner-name='" + response.user.name + "'  href='javascript:;' class='updateOwnerEmail current " + 
              response.user.id + "'> <span class='email'>" + response.user.email + "</span> <small class='company'>" + response.company_name + "</small> </a> </div> </li>"
          } else {
            var li_attributes = "<li id=" + response.user.id + "> <div> <a owner-id='" + response.user.id +
              "'owner-email='" + response.user.email + "'owner-name='" + response.user.name + 
              "'uses-pricing-v2=" + response.uses_pricing_v2 +
              " company-id='" + response.company_id + "' href='javascript:;' class='updateOwnerEmail " +
              response.user.id + "'> <span class='email'>" + response.user.email +
              "</span> <small class='company'>" + response.company_name + "</small> </a> </div> </li>"
          }
          $(".OwnersSearchResult").append(li_attributes);
        }
      }
    }
  });
}

function validateEmail($email) {
  var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
  return emailReg.test( $email );
}

