$(function(){
	$(document).on("click",".burgerMenu", function(e){
		e.preventDefault();
		var obj = $(this);
		if(obj.hasClass("active")){
			obj.removeClass("active");
			$(".mainMenu").removeClass("show");
		}else{
			obj.addClass("active");
			$(".mainMenu").addClass("show");
		}
	});
	//setTimeout(function(){
		$(".mainItems").addClass("active");
	//},800);	

	$(document).on("click",".prebootBtn", function(e){
    e.preventDefault();
    if($(this).hasClass("active")){
      $(this).removeClass("active");
    }else{
      $(this).addClass("active");
    }
  });

	loader = function(action, message, handler){
		handler  = handler ? handler : "blueloader";
		message = message ? message : "Please wait...";
		action = action ? action : "show";
		if(action == "show"){
			html_loader = '<div class="blueloader">' + 
						  '<div data-handler="'+handler+'" class="blueloader-content" style="left: 0; top: 0;">' + 
						  '<div class="svgLoaderCircle" style="width:120px;height:120px;background:#ffffff">' + 
						  '<div style="width:100%;height:100%;margin:0;padding:0;">' + 
						  '<div>' + 
						  '<svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">' + 
						  '<circle cx="75" cy="50" fill="#2bb26e" r="3">' + 
						  '<animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="0.6s" repeatCount="indefinite" begin="-0.55s">' + 
						  '</animate>' + 
						  '<animate attributeName="fill" values="#2ba4b2;#2ba4b2;#2bb26e;#2ba4b2;#2ba4b2" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="0.6s" begin="-0.55s">' + 
						  '</animate>' + 
						  '</circle>' + 
						  '<circle cx="71.651" cy="62.5" fill="#2bb26e" r="3">' + 
						  '<animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="0.6s" repeatCount="indefinite" begin="-0.5s">' + 
						  '</animate>' + 
						  '<animate attributeName="fill" values="#2ba4b2;#2ba4b2;#2bb26e;#2ba4b2;#2ba4b2" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="0.6s" begin="-0.5s">' + 
						  '</animate>' + 
						  '</circle>' + 
						  '<circle cx="62.5" cy="71.651" fill="#2bb26e" r="3">' + 
						  '<animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="0.6s" repeatCount="indefinite" begin="-0.45s">' + 
						  '</animate>' + 
						  '<animate attributeName="fill" values="#2ba4b2;#2ba4b2;#2bb26e;#2ba4b2;#2ba4b2" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="0.6s" begin="-0.45s">' + 
						  '</animate>' + 
						  '</circle>' + 
						  '<circle cx="50" cy="75" fill="#2bb26e" r="3">' + 
						  '<animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="0.6s" repeatCount="indefinite" begin="-0.4s">' + 
						  '</animate>' + 
						  '<animate attributeName="fill" values="#2ba4b2;#2ba4b2;#2bb26e;#2ba4b2;#2ba4b2" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="0.6s" begin="-0.4s">' + 
						  '</animate>' + 
						  '</circle>' + 
						  '<circle cx="37.5" cy="71.651" fill="#2bb26e" r="3">' + 
						  '<animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="0.6s" repeatCount="indefinite" begin="-0.35s">' + 
						  '</animate>' + 
						  '<animate attributeName="fill" values="#2ba4b2;#2ba4b2;#2bb26e;#2ba4b2;#2ba4b2" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="0.6s" begin="-0.35s">' + 
						  '</animate>' + 
						  '</circle>' + 
						  '<circle cx="28.349" cy="62.5" fill="#2bb26e" r="3.22222">' + 
						  '<animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="0.6s" repeatCount="indefinite" begin="-0.3s">' + 
						  '</animate>' + 
						  '<animate attributeName="fill" values="#2ba4b2;#2ba4b2;#2bb26e;#2ba4b2;#2ba4b2" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="0.6s" begin="-0.3s">' + 
						  '</animate>' + 
						  '</circle>' + 
						  '<circle cx="25" cy="50" fill="#2bb26e" r="3.88889">' + 
						  '<animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="0.6s" repeatCount="indefinite" begin="-0.25s">' + 
						  '</animate>' + 
						  '<animate attributeName="fill" values="#2ba4b2;#2ba4b2;#2bb26e;#2ba4b2;#2ba4b2" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="0.6s" begin="-0.25s">' + 
						  '</animate>' + 
						  '</circle>' + 
						  '<circle cx="28.349" cy="37.5" fill="#2bb26e" r="4.55556">' + 
						  '<animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="0.6s" repeatCount="indefinite" begin="-0.2s">' + 
						  '</animate>' + 
						  '<animate attributeName="fill" values="#2ba4b2;#2ba4b2;#2bb26e;#2ba4b2;#2ba4b2" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="0.6s" begin="-0.2s">' + 
						  '</animate>' + 
						  '</circle>' + 
						  '<circle cx="37.5" cy="28.349" fill="#2bb26e" r="4.77778"><animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="0.6s" repeatCount="indefinite" begin="-0.15s">' + 
						  '</animate>' + 
						  '<animate attributeName="fill" values="#2ba4b2;#2ba4b2;#2bb26e;#2ba4b2;#2ba4b2" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="0.6s" begin="-0.15s">' + 
						  '</animate>' + 
						  '</circle>' + 
						  '<circle cx="50" cy="25" fill="#2bb26e" r="4.11111">' + 
						  '<animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="0.6s" repeatCount="indefinite" begin="-0.1s">' + 
						  '</animate>' + 
						  '<animate attributeName="fill" values="#2ba4b2;#2ba4b2;#2bb26e;#2ba4b2;#2ba4b2" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="0.6s" begin="-0.1s">' + 
						  '</animate>' + 
						  '</circle>' + 
						  '<circle cx="62.5" cy="28.349" fill="#2bb26e" r="3.44444">' + 
						  '<animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="0.6s" repeatCount="indefinite" begin="-0.05s">' + 
						  '</animate>' + 
						  '<animate attributeName="fill" values="#2ba4b2;#2ba4b2;#2bb26e;#2ba4b2;#2ba4b2" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="0.6s" begin="-0.05s">' + 
						  '</animate>' + 
						  '</circle>' + 
						  '<circle cx="71.651" cy="37.5" fill="#2bb26e" r="3">' + 
						  '<animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="0.6s" repeatCount="indefinite" begin="0s">' + 
						  '</animate>' + 
						  '<animate attributeName="fill" values="#2ba4b2;#2ba4b2;#2bb26e;#2ba4b2;#2ba4b2" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="0.6s" begin="0s">' + 
						  '</animate>' + 
						  '</circle>' + 
						  '</svg>' + 
						  '</div>' + 
						  '</div>' + 
						  '</div>'+message+'</div>' + 
						  '</div>';
			$("body").append(html_loader);
		}else{
			$(".blueloader").remove();
		}
	}

});

